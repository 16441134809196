<template>
	<div class="modal-wrapper"
			 v-if="user">
		<div class="modal-overlay"
				 @click="closeModal"></div>

		<section class="user-details-modal modal card shadow"
						 role="dialog">
			<button class="button--icon button-close"
							@click="closeModal">
				<svg role="img"
						 class="icon"
						 xmlns="http://www.w3.org/2000/svg"
						 width="14"
						 height="14"
						 fill="none"
						 viewBox="0 0 14 14">
	          <title>{{ $t("Fermer") }}</title>
	          <path
	            fill="currentColor"
	            fill-rule="evenodd"
	            d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
	            clip-rule="evenodd"
	          />
	          <path
	            fill="currentColor"
	            fill-rule="evenodd"
	            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
	            clip-rule="evenodd"
	          />
	        </svg>
			</button>

			<header class="flex-row flex-center-v flex-center-h">
				<div class="flex-item">
					<picture class="avatar">
						<img role="img"
								 :src="user.avatar"
								 :alt="`${user.firstname[0]}${user.lastname[0]}`"
								 width="60"
								 height="60" />
					</picture>
				</div>
				<div class="flex-item--grow">
					<h2>{{ user.firstname }} {{ user.lastname }}</h2>
					<p>
						<strong>{{ $t(user.specialty) }}</strong>
						<br />{{ user.city }}<span v-if="user.country">, {{ user.country }}</span>
					</p>
				</div>
			</header>
		</section>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

.user-details-modal {
	[class*="flex-item"] {
		margin: 1rem;
	}

	.avatar {
		display: block;
		height: 6rem;
		width: 6rem;
		border-width: 2px;
	}

	.button-close {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
	}
}

@media all and (max-width: 768px) {
	.user-details-modal.modal {
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		transform-origin: center;
	}
}
</style>

<script>
import countries from "../libs/countries.json";

export default {
	name: "UserDetails",
	props: ["userId"],

	data: function () {
		return {
			user: null,
		};
	},

	methods: {
		// Load user data from database
		loadUserData: function () {
			this.$store
				.dispatch("GET_USER_DATA", this.userId)
				.then((response) => {
					this.user = response;
				})
				.catch((error) => {
					console.warn(error);
				});
		},

		closeModal: function () {
			this.$emit("modalClose");
		},
	},

	mounted() {
		if (this.userId) this.loadUserData();
	},
};
</script>
