<template>
  <div class="modal-wrapper modal-wrapper--mailto">
    <div class="modal-overlay"></div>

    <section class="mailto-modal modal card shadow rythm-v" role="dialog">
      <details open>
        <summary class="flex-row flex-center-v flex-align-spaced flex-nowrap">
          <h2 class="flex-row flex-center-v flex-gap-s flex-nowrap">
            <svg role="img" aria-hidden="true" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon icon--envelope">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.75 0C1.23122 0 0 1.23122 0 2.75V11.25C0 12.7688 1.23122 14 2.75 14H13.25C14.7688 14 16 12.7688 16 11.25V2.75C16 1.23122 14.7688 0 13.25 0H2.75ZM2.63758 1.50499C2.67462 1.50169 2.71211 1.5 2.75 1.5H13.25C13.2879 1.5 13.3254 1.50169 13.3624 1.50499L8.00001 6.24867L2.63758 1.50499ZM1.52111 2.52002C1.50725 2.59456 1.5 2.67144 1.5 2.75V11.25C1.5 11.9404 2.05964 12.5 2.75 12.5H13.25C13.9404 12.5 14.5 11.9404 14.5 11.25V2.75C14.5 2.67144 14.4928 2.59457 14.4789 2.52003L8.49694 7.81176C8.2132 8.06276 7.78682 8.06276 7.50308 7.81176L1.52111 2.52002Z"
                fill="currentColor"
              ></path>
            </svg>
            <span class="caption">{{ $t("Demande d’échange entre experts") }}</span>
          </h2>
          <svg class="icon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
            <path d="M14 5l-6.5 7L1 5" fill="none" stroke="currentColor" stroke-linecap="square"></path>
          </svg>
        </summary>

        <form @submit.prevent="sendEmail">
          <div class="flex-row rythm-v">
            <!-- <div class="flex-item--100">
            <div class="input-group rythm-v">
              <label for="experts">Destinataires</label>
              <multiselect
                name="experts"
                id="experts"
                label="name"
                track-by="id"
                :multiple="true"
                :searchable="true"
                :preselect-first="false"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                :internal-search="true"
                selectLabel=""
                selectedLabel="Sélectionné"
                deselectLabel="Supprimer"
                tag-placeholder="Ajouter"
                placeholder="Rechercher dans la liste"
                v-model="experts"
                :options="mutiselectOptions"
              >
                <span slot="noResult">Aucun résultat…</span>
                <span slot="noOptions">Aucun expert !</span>
              </multiselect>
            </div>
          </div> -->

            <div class="flex-item--100">
              <div class="input-group rythm-v">
                <label hidden for="message">{{ $t("Votre message") }}</label>
                <p class="text-small">
                  <em>{{ $t("Merci de ne pas partager d'informations personnelles ou données de santé par e-mail.") }}</em>
                </p>
                <p class="text-bold flex-item--100">{{ question.title }}</p>
                <textarea name="message" id="message" cols="30" rows="4" v-model="emailBody"></textarea>
              </div>
            </div>

            <div class="input-group flex-item--100 flex-row flex-align-left flex-gap">
              <button class="button--primary" type="submit" :disabled="!readyToSend">
                <span class="caption">{{ $t("Envoyer") }}</span>
                <svg class="icon icon-send" role="img" aria-hidden="true" fill="none" height="16" viewBox="0 0 14 16" width="14" xmlns="http://www.w3.org/2000/svg">
                  <path
                    clip-rule="evenodd"
                    d="m13.345 1.64566c.0147-.24055-.1104-.46862-.3232-.58911s-.4768-.112728-.6818.02005l-11.702467 7.57733c-.23165.14999-.340325.4279-.270344.69134.069982.26343.303111.45401.579942.47409l6.632549.48114 3.74122 5.3924c.1561.2251.441.3278.7086.2555.2676-.0722.4587-.3035.4753-.5752zm-5.76338 7.37907-4.64181-.33674 9.03229-5.84841-.6485 10.59532-2.61829-3.77396 1.09391-1.8581c.17915-.3043.07283-.69341-.23746-.8691-.3103-.17569-.70707-.07143-.88622.23287z"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </button>
              <button class="button--outline" type="button" @click="closeModal">{{ $t("Fermer") }}</button>
            </div>
          </div>
        </form>
      </details>
    </section>
  </div>
</template>

// <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import "~@/scss/01-settings/settings.colors";
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";
@import "~@/scss/06-components/vue-multiselect";
@import "~@/scss/99-utilities/_utilities.text";

.modal-wrapper[open].modal-wrapper--mailto {
  width: 0;
  height: 0;

  .modal-overlay {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .mailto-modal {
    right: 1rem;
    bottom: 1rem;
    top: initial;
    left: initial;
    transform: none;
    width: 40rem;
    max-width: 100%;
    padding: 1rem;
    border-color: $color-cta;
    box-shadow: 0 -8px 12px -5px #0000000f, 0 0 40px #0000001c;
  }
}

.mailto-modal {
  summary {
    h2 {
      color: $color-cta;
      .icon {
        width: 1em;
        height: auto;
      }
    }

    & > .icon {
      width: 22px;
      height: auto;
      color: $color-cta;
      transform: rotate(180deg);
    }
  }

  details[open] {
    summary {
      & > .icon {
        transform: rotate(0);
      }
    }
  }

  textarea {
    height: 20em;
    &:focus {
      outline: 1px solid currentColor;
    }
  }
}

@media (max-width: 768px) {
  .modal-wrapper[open].modal-wrapper--mailto .modal {
    bottom: 0;
    height: calc(100vh - 2rem);
    left: 1rem;
    overflow: scroll;
    right: 1rem;
    top: 1rem;
    width: auto;
  }

  .modal-wrapper[open].modal-wrapper--mailto .user-details-modal {
    height: auto;
  }
}

.input-group--controls {
  justify-content: center;
  button {
    margin: 0.5rem 0.5rem 0;
  }
}
</style>

<script>
// import Multiselect from "vue-multiselect";
import { HTTP } from "../http-common.js";

export default {
  name: "MailtoModal",
  // components: { Multiselect },
  props: ["question"],

  data: function() {
    return {
      // experts: [],
      emailBody: "",
    };
  },

  computed: {
    readyToSend() {
      return this.mail.body.length > 4; //&& this.mail.recipients.length;
    },

    // Format options
    // mutiselectOptions() {
    //   // Filter users
    //   let experts = this.users.filter((el) => {
    //     let isExpert = el.roles.split(",").includes("2");
    //     let isExpertExt = el.roles.split(",").includes("5");
    //     if (isExpert || isExpertExt) return el;
    //   });

    //   // Return options
    //   let options = experts.map((el) => {
    //     return {
    //       id: el.id,
    //       name: `${el.firstname} ${el.lastname}`,
    //     };
    //   });

    //   return options;
    // },

    mail() {
      if (!this.question) {
        return {
          questionID: 0,
          category: "",
          body: "",
        };
      }
      return {
        questionID: this.question.id,
        category: this.question.primary_themes[0].name,
        body: this.emailBody.trim(),
        // recipients: this.experts.length ? this.experts.map((o) => o["id"]) : [],
      };
    },
  },

  methods: {
    sendEmail: function() {
      let payload = this.mail;

      const self = this;

      HTTP.post("/mailing/experts", payload)
        .then(function(response) {
          const payload = response.data.payload || null;

          self.$toasted.global.appSuccess({
            message: self.$t("Demande envoyée !"),
          });

          self.emailBody = "";
          self.closeModal();
        })
        .catch(function(error) {
          let errorMsg = self.$t("Erreur lors de l’envoi !");
          if (error.status === 404) {
            errorMsg = self.$t("Aucun expert actif a été trouvé…");
          }

          self.$toasted.global.appError({ message: errorMsg });
        });

      // this.$emit("sendExpertsMail", this.mail);
    },

    closeModal: function() {
      this.$emit("modalClose");
    },
  },
};
</script>
